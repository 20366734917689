<template>
  <transition name="menu">
    <form-case :is-edit="false"/>
  </transition>
</template>

<script>
import formCase from './components/formCase'

export default {
  name: 'CreateformCase',
  components: { formCase }
}
</script>

